<template>
    <div id="bj">
      <div class="login-box">
        <!-- 通过:rules="loginFormRules"来绑定输入内容的校验规则 -->
        <el-form :rules="loginFormRules" ref="loginForm" :model="loginForm" label-position="right" label-width="auto" show-message>
            <div style="margin-top: 30px"></div>
            <span class="login-title">陆遥建站宝</span>
            <div style="margin-top: 20px"></div>
            <el-form-item  prop="loginName">
                <el-col :span="20">
                    <el-input class="login-input" type="text"  placeholder="Username" v-model="loginForm.loginName" @keyup.enter.native="loginSubmit('loginForm')"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item  prop="loginPassword">
                <el-col :span="20">
                    <el-input class="login-input" type="password" @keyup.enter.native="loginSubmit('loginForm')"  placeholder="Password" v-model="loginForm.loginPassword"></el-input>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-button class="submit" type="primary" @click="loginSubmit('loginForm')" round>LOGIN</el-button>
            </el-form-item>
        </el-form>
    </div>
    </div>
   
    
</template>
<script>
import Cookies from 'js-cookie';
let Base64 = require('js-base64').Base64;
    export default {
        name: "login",
        data() {
            return {
                loginForm: {
                    loginName: '',
                    loginPassword: ''
                },
                // 表单验证，需要在 el-form-item 元素中增加 prop 属性
                loginFormRules: {
                    loginName: [
                        {required: true, message: '账号不可为空', trigger: 'blur'}
                    ],
                    loginPassword: [
                        {required: true, message: '密码不可为空', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {

             make_base_auth(user, password) {
                var tok = user + ':' + password;
                var hash = Base64.encode(tok);
                return "Basic " + hash;
            },
            loginSubmit(formName) {
              
                // 为表单绑定验证功能
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        this.$api.post('/user/login',null,{"userName":this.loginForm.loginName,"userPass":this.loginForm.loginPassword},
                        successRes => {     
                            let status = successRes.data; 
                            if(status){
                                window.localStorage.setItem('token', this.loginForm.loginName);
                                Cookies.set('username',this.loginForm.loginName);
                                Cookies.set('token',this.make_base_auth(this.loginForm.loginName,this.loginForm.loginPassword));
                                this.$router.push({ path: "/management/desk/deskList"});
                            }else{
                                 this.$message.error('用户名或者密码错误。');
                            }
                        },
                        failureRes =>{                        
                            console.log(failureRes)
                        })
                    }
                    
        });
            }
        }
    }
</script>
<style >

    /* .login-input{ */
        /* border: #000000 solid 1px; */
        /* border-radius:15px;

    } */

</style>
<style scoped>
body,html{
  width: 100%;
     height: 100%;
     position: relative;
      margin: 0;
     padding: 0;
}
template{
  margin: 0;
  padding: 0;
}
   #bj{
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0;
     left: 0;
     margin: 0;
     padding: 0;
   }
   .login-box {
        width: 300px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: none;
        padding-left: 30px;
    
    }
    .login-title {
        text-align: center;
        margin-left: 80px;
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        
    }
    .el-col>>>.el-input__inner{
        border-radius:20px;
        border: solid 2px #b1b1b1;
        background-color: transparent;

    }
    
    .submit{
      width: 250px;
      background-color: #000000;
      border: none;
    }
</style>
